/* Css Tentang Kami */

.ts-title-tentang span {

    color: #6cc8d3;

}



.ts-title-tentang strong {

    color: #f27212;

}



.ts-title-tentang h2 {

    color: #585858;

}



.ts-title-tentang .p-2 {

    margin-top: -20px;

}



/* Css Layanan */

.ts-title-layanan span {

    color: #6cc8d3;

}



.ts-title-layanan strong {

    color: #f27212;

}



.ts-title-layanan h2 {

    color: #585858;

}



.ts-item-body h5{

    color: #29b0bf;

}



/* Css Header  */

.header-custom h2 {

    font-weight: 200;

    color: #fff;

}



.header-custom h2 strong {

    font-weight: 700;

}



.header-custom img {

    z-index: 999;

    margin-bottom: -100px;

    margin-left: -230px;

    width: 165%;

}



.header-custom {

    padding-top: 8rem;

}



/* Css Layanan Digital */

.ts-layanan-card .card {

    padding: 20px;

    height: 100%;

}



.ts-layanan-card .ts-item-body p {

    font-size: 13px;

    line-height: 25px;

}



.tentang-img img {

    width: 100%;

}



/* Css Social Media Management */

.pick {

    padding-right: 30px;

    display: flex;

    width: 85%;

}



.pick p {

    margin-bottom: 5px;

}



.pick i {

    color: #07D553;

    padding: 5px 0;

    margin-right: 10px;

}



/* Paket Layanan */

.paket-layanan {

    background-color: #f5860e;

}



.ts-title-paket {

    padding-left: 20%;

}



.ts-title-paket span {

    font-weight: 300;

}



.ts-title-paket h2 {

    font-weight: 700;

}



.ts-title-paket .pick {

    padding-right: 30px;

    display: flex;

    width: 85%;

}



.ts-title-paket .pick p {

    margin-bottom: 10px;

    color: white;

}



.ts-title-paket .pick i {

    color: white;

    padding: 5px 0;

    margin-right: 10px;

}





/* Teknis Kerja */

.teknis-kerja {

    background-color: #f5900e;

}



.ts-title-teknis {

    padding-right: 20%;

    padding-left: 5%;

}



.ts-title-teknis h2 {

    font-weight: 700;

}



.ts-title-teknis h6 {

    font-size: 18px;

    font-weight: 500;

}



.ts-title-teknis p {

    color: white;

    padding-left: 20px;

    font-weight: 400;

    line-height: 22px;

}



/* Video & Podcast */

.pick li {

    color: #d72293;

    padding: 5px 0;

    font-size: 13px;

    margin-left: 25px;

}





/* Portfolio */

.container-filter a {

    display: block;

    color: #b9b9b9 !important;

    border: 1px solid #eeeeee;

    padding: 0px 22px;

    margin: 4px 8px !important;

    cursor: pointer;

    line-height: 35px;

    -webkit-transition: all 0.6s;

    transition: all 0.6s;

    border-radius: 5px;

    background-color: #eeeeee;

}



.container-filter a.active {

    background-color: #f5860e;

    color: #ffffff !important;

    font-weight: 700;

}



.container-filter a:hover {

    background-color: #f5860e !important;

    color: #ffffff !important;

}





.container-filter li {

    list-style: none;

    display: inline-block;

}



.projects-wrapper a img {

    border-radius: 5px;

    width: 215px;

    height: 142px;

    object-fit: cover;

}



/* Footer */

footer {

    background-color: #f68d0c;

}



footer .contact span {

    color: #2dc9a3;

}



footer .contact h2 {

    color: #f68d0c;

    font-weight: 700;

    margin-bottom: 2%;

}



footer .contact .btn {

    border-radius: 5px;

    border-width: .125rem;

    box-shadow: .125rem .1875rem .9375rem rgba(0, 0, 0, .15);

    font-weight: 600;

    font-size: .9375rem;

    padding: .5rem 1.25rem;

    position: relative;

    outline: none !important;

    width: 15%;

}



footer .kreativa-footer p {

    color: #fff;

    font-weight: 400;

    margin-bottom: 10px;

    line-height: 150%;

}



footer .footer-judul {

    font-weight: 700 !important;

}



/* Slick custom */

.slick-dots li button:before {

    font-size: 0 !important;

}



.slick-dots{

    bottom: -40px !important;

}

.slick-dotted.slick-slider{

    margin-bottom: 0 !important;

}



.slick-dots li button {

    width: .5rem !important;

    height: .5rem !important;

    border-radius: 50%;

    background-color: #e2e2e2 !important;

    border: 1px #e2e2e2;

}

li.slick-active button {

    background-color: #FDA73A !important;

}




/* kontak */

#buy-now {

    min-height: 100px;

}



@media (max-width:767px) {

	.hidden-xs {

		display: none!important

    }



    /* Header */

    .header-custom img {

        z-index: 999;

        /* margin-bottom: -100px; */

        margin-left: 20px;

        margin-top: 10px;

        margin-bottom: 30px;

        width: 80%;

    }

    .header-custom h2 {

        font-size: 30px;

        margin-left: 10px;

    }



    .header-custom p {

        font-size: 16px;

        margin-left: 10px;

        margin-top: 20px;

    }



    .header-custom .w-75  {

        width: 90% !important;

    }



    /* Css Tentang Kami */

    .tentang-kami-img img {

        width: 70%;

        height: 70%;

        object-fit: cover;

        margin-left: auto;

        margin-right: auto;

    }



    .ts-title-tentang span {

        font-size: 16px;

    }



    .ts-title-tentang h2 {

        font-size: 25px;

    }



    .ts-title-tentang p {

        font-size: 16px;

    }



    .ts-title-tentang .p-2 {

        margin-top: 0px !important;

    }



    /* Css Layanan */

    .ts-title-layanan span {

        font-size: 16px;

    }



    .ts-title-layanan p {

        margin-top: 10px;

        font-size: 16px;

    }



    .ts-title-layanan h2 {

        font-size: 25px;

    }



    .ts-layanan-card .card {

        width: 90%;

        margin-left: 20px;

    }



    .ts-item-body h5{

        font-size: 16px;

        font-weight: 700;

    }



    .ts-layanan-card .ts-item-body p {

        font-size: 15px;

    }



    .tentang-img img {

        width: 95% !important;

        margin-left: 25px !important;

    }



    /* Social Media */

    .ts-title-social {

        margin-left: 25px;

    }



    .ts-title-social span {

        font-size: 16px;

    }



    .ts-title-social h2 {

        font-size: 26px;

    }



    .ts-title-social p {

        font-size: 17px;

    }



    /* Paket Layanan */

    .ts-title-paket {

        padding-left: 10%;

    }



    .ts-title-paket span {

        font-size: 16px;

    }



    .ts-title-paket h2 {

        font-size: 23px;

    }



    .ts-title-paket p {

        font-size: 15px;

    }



    .ts-title-paket {

        padding-left: 10%;

    }



    .ts-title-teknis {

        padding-left: 10%;

    }



    .ts-title-teknis span {

        font-size: 16px;

    }



    .ts-title-teknis h2 {

        font-size: 23px;

    }



    .ts-title-teknis p {

        font-size: 15px;

    }



    /* Fotografi */

    .fotografi-img img {

        width: 95%;

    }



    .ts-title-fotografi {

        margin-left: 15px;

    }



    .ts-title-fotografi .pick-atas {

        margin-top: 15px !important;

    }



    .ts-title-fotografi .pick {

        margin-top: 7px;

    }





    .ts-title-portfolio-mobile {

        margin-left: 8%;

    }



    .ts-title-portfolio-mobile span {

        color: #6cc8d3;

        font-size: 15px;

    }



    .ts-title-portfolio-mobile strong {

        color: #f27212;

    }



    .ts-title-portfolio-mobile h2 {

        color: #585858;

        font-size: 25px;

    }





    /* Slick Custom */

    .slick-list{

        padding:0 20% 0 0 !important;

    }



    .slick-slide {

        margin-right: 10px !important;

        margin-left: 65px !important;

    }



    .btn-primary {

        width: auto !important;

    }



    /* Kontak */

    #ts-footer .ts-box {

        margin-top: -13.125rem !important;

        margin-left: auto;

        margin-right: auto;

        width: 90%;

    }



    #test {

        min-height: 55px;

    }



    .contact span {

        font-size: 15px;

    }



    .contact h2 {

        font-size: 25px;

        margin-bottom: 7% !important;

    }



    /* Ordering CSS Flexing */

    .wrap {

        display: flex;

        flex-direction: row;

      }

      /* Ini untuk photography */

      .box:nth-child(1) { order: 2; }

      .box:nth-child(2) { order: 1; }

      /* Ini Header */

      .box1:nth-child(1) { order: 2; }

      .box1:nth-child(2) { order: 1; }





}



@media (min-width:768px) and (max-width:991px) {

	.hidden-sm {

		display: none!important

    }

}



@media (min-width:992px) and (max-width:1199px) {

	.hidden-md {

		display: none!important

    }



}



@media (min-width:1200px) {

	.hidden-lg {

		display: none!important

	}



    #ts-footer .ts-box {

        margin-top: -13.125rem !important;

    }

    #portfolio {

        min-width: 200px;

        /* padding-bottom: 5.125rem !important; */

        /* padding-bottom: 8.125rem !important; */

        /* padding-top: 6.125rem !important; */

    }



    #test {

        min-width: 100px;

    }



    .ts-block-custom {

        padding-top: 6.125rem !important;

    }

}





.navbar {

    padding-bottom: 1rem !important;

    padding-top: 1rem !important;

}



@media (min-width: 62rem) {

    .navbar.navbar-expand-lg .ts-background {

        opacity: 1 !important;

    }

}
