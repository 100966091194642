@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css?family=Muli');

        .blue--text{
            color: #19214d;
            font-weight: 500;
        }
        .mt-m160{
            margin-top: -160px;
        }
        
        .me-auto {
            margin-right: auto!important;
        }
        .nav-menu > ul {
            margin: 0;
            padding: 0;
            display: flex;
            list-style: none;
            align-items: center;
        }
        .nav-menu .getstarted {
            background: #d9232d;
            padding: 8px 25px;
            margin-left: 30px;
            border-radius: 4px;
            font-weight: 400;
            color: #fff;
        }

        .btn--custom{
            color: #ac6707;
            font-family: 'Rubik', sans-serif;
            background-color: #FCBB67;
            border-color: #FCBB67;
            box-shadow: 3px 3px #ac6707;
            width: 180px;
            font-weight: 600;
        }

        #hero{
            background: url('assets/img/Group 163.png');
            padding: 100px 0 0 0;
            max-height: auto;
            background-size: cover;
        }

        .img--section{
            width: 100%;
            height: auto;
        }

        .btn--gabung{
            background-color: #FDA73A;
            border-color: #19214D;
            color: #19214D;
            box-shadow: 3px 3px #18204A;
            width: 150px;
            font-family: "Rubik", sans-serif !important;
        }

        .btn--gabungs{
            width:100%;
            height:50px
        }

        .btn--gabungs:hover{
            color: #d9232d;
        }

        .btn--gabung--card{
            background-color: #FFFFFF;
            /*border-color: #A1A1A1*/
            border: 2px solid #19214D;
            border-radius: 4px;;
            color: #19214D;
            width: 180px;
            font-family: "Rubik", sans-serif !important;
            position: relative;
            margin-top: -30px;
        }

        #hero h1 p {
            font: normal normal normal 49px Rubik;
            letter-spacing: 0px;
            margin: 0px;
            display: inline;
        }
        #hero h1 label {
            font: normal normal bold 49px Rubik;
            color: #FDA73A;
        }
        #about .content .col-lg-6.about-img{
            padding-top: 138px;
        }

        .pr-8 {
            padding-left: 6rem !important;
            padding-right: 3rem !important;
        }



        /* Responsive */
        @media (min-width: 320px) and (max-width: 425px) {
            #hero .row {
                margin-right: 15px !important;
                margin-left: 15px !important;
            }
            #about .row, .content .row {
                margin-right: 0px !important;
                margin-left: 0px !important;
            }
            .img-logo {
                max-width: 100%;
                height: 27px;
                margin-left:5%
            }
            /* .slick-prev, .slick-next{
                display: none !important;
            } */
            .slick-dots li button{
                width: 10px !important;
                height: 10px !important;
            }
            .pr-8 {
                padding-left: 1rem !important;
                padding-right: 1rem !important;
            }
            .me-auto {
                margin-left: 60px !important;
            }
            .img--section{
                padding-top: 15%
            }
            /* .hidden-md,.hidden-lg{
                display: none;
            } */
            #hero h1 p {
                font: normal normal normal 30px Rubik;
                letter-spacing: 0px;
                margin: 0px;
            }
            #hero h1 label {
                font: normal normal bold 30px Rubik;
                color: #FDA73A;
            }
            button.btn.btn--custom {
                font: normal normal normal 17px Rubik;
                color: #AC6707;
                font-weight: 500;
            }
            section {
                padding: 0px;
            }
            #about .content-column {
                display: flex;
                flex-direction: row;
            }
            /* #about .content-column :nth-child(1) { order: 2; }
            #about .content-column :nth-child(2) { order: 1; } */
            .logo-project-mb-sm{
                margin-bottom: 30% !important;
            }
            .title-project-mb-sm{
                margin-bottom: 0px !important;
            }

            #about .content h2 {
                font: normal normal bold 26px Rubik;
                letter-spacing: 0px;
                color: #19214D;
                margin-top: 10%
            }
            #about .content p {
                margin-top: 25px;
                font: normal normal normal 16px Rubik;
                letter-spacing: 0px;
                color: #474747;
            }
            .sub-benefit{
                padding-right: 18% !important;
            }
            .col-benefit-sm-3{
                position: relative;
                width: 33.3%;
                padding-right: 0px !important;
                padding-left: 0px !important;
            }
            .col-need-sm-3{
                position: relative;
                width: 33.3%;
                padding-right: 0px !important;
                padding-left: 15px !important;
            }
            .col-benefit-sm-9{
                position: relative;
                width: 66.7%;
                padding-right: 0px !important;
                padding-left: 15px !important;
            }
            .col-need-sm-9{
                position: relative;
                width: 66.7%;
                padding-right: 15px !important;
                padding-left: 0px !important;
            }
            .title-benefit{
                font-size:1rem !important;
            }
            .text-desc-2 {
                margin-top:5px !important;
                font-size: 13px !important;
                line-height: 1.5;
            }
            .box-need{
                margin-top: 5%
            }
            .clients .card{
                height: 250px !important;
            }
            .logo-putih{
                text-align: center;
            }
            li.slick-active button{
                background-color : #FDA73A;
            }
            ul.slick-dots li button{
                border-radius:50%
            }
            .slick-slide img.cek-spek {
                display: inline-block !important;
            }
            .slick-slide img.logo--need {
                max-width: 78% !important;
            }
            .mb-sm-0{
                margin-bottom: 0px !important;
            }
            .mb-sm-10{
                margin-bottom: 10% !important;
            }
            #footer .footer-top p{
                font-size: 18.5px;
            }
            .slick-dots li button:before,.slick-dots li.slick-active button:before
            {
                opacity: 0;
            }
            #about .content .col-lg-6.about-img{
                padding-top: 20%;
            }



        }


        /* Baru */
        .nav-menu a {
            color: #19214D;
        }



        .text-desc-header {
            color: #fff;
            font-size: 18px;
            font-weight: 300;
            font-family: 'Muli'
        }

        .fw-700 {
            font-weight: 700;
        }

        .fw-500 {
            font-weight: 500;
        }

        .text-blue-dark {
            color: #19214D;
        }

        .text-abu {
            color: #474747;
        }

        .card-body-need{
            flex: 1 1 auto;
            min-height: 1px;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            padding-top: 1.25rem;
            padding-bottom : 40% !important;
        }

        .fs-17 {
            font-size: 17px;
        }

        .title-need{
            font-size:1.8rem!important;
        }

        .text-desc-2 {
            font-size: 15px;
            line-height: 1.5;
        }

        @media screen and (min-width: 992px) and (max-width: 1280px) {
            /* .pad-but-custom{
                padding:2px;
            } */
            .text-banners h1.text-white p,.text-banners h1.text-white label{
                font-size:2.4rem !important;
            }
            .text-desc-header{
                font-size:17px;
            }
        }

        @media screen and (min-width: 992px) and (max-width: 1200px) {
            .text-banners h1.text-white p,.text-banners h1.text-white label{
                font-size:2.2rem !important;
            }
            .text-desc-header{
                font-size:14px;
                margin-bottom: 2rem !important;
            }
            .pad-but-custom{
                padding: 0.5%;
            }
        }
